import Backdrop from '@common_backdrop';
import CmsRenderer from '@core_modules/cms/components/cms-renderer';
import dynamic from 'next/dynamic';

const Breadcrumb = dynamic(() => import('@common_breadcrumb'), { ssr: false });

const CmsPage = (props) => {
    const {
        data, t, loading, error, storeConfig, onlyCms, ...other
    } = props;
    if (error) {
        return (
            <div className="alert m-15" severity="error">
                {t('common:error:fetchError')}
            </div>
        );
    }

    if (loading) return <Backdrop open={loading} />;
    if (onlyCms) return <CmsRenderer {...other} t={t} content={data.cmsPage.content} storeConfig={storeConfig} />;

    const breadcrumbsData = [
        {
            label: data.cmsPage.title,
            link: '#',
            active: true,
            id: '',
        },
    ];

    const isHomepage = data.cmsPage.identifier === 'home';

    return (
        <div className="cms-container">
            { !isHomepage && (
                <div class="desktop:max-w-[1440px] desktop:px-10 tablet:max-w-[1024px] tablet:px-6
                    my-0 mx-auto mobile:px-4 mb-0 desktop:mt-6 xs:mt-4"
                >
                    <Breadcrumb
                        withHome
                        iconHomeOnly
                        className="desktop:mt-[0px] tablet:mt-[0px] mobile:py-space-300 desktop:mb-space-500"
                        data={breadcrumbsData}
                    />
                </div>
            )}

            <CmsRenderer {...other} t={t} content={data.cmsPage.content} storeConfig={storeConfig} />
        </div>
    );
};

export default CmsPage;
